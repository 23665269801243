$switch-background: $grey-dark;

@mixin switch-size($size) {
  + label {

    &::before,
    &:before {
      background: $switch-background; } }

  &.is-outlined {
    + label {
      &::before,
      &:before {
        background-color: transparent;
        border-color: $switch-background; }
      &::after,
      &:after {
        background: $switch-background; } }
    &:checked {
      + label {
        &::before,
        &:before {
          background-color: transparent;
          border-color: $switch-background-active; }
        &::after,
        &:after {
          background: $switch-paddle-background-active; } } } } }

.switch[type="checkbox"] {

  @include switch-size($size-normal);
  &.is-small {
    @include switch-size($size-small); }
  &.is-medium {
    @include switch-size($size-medium); }
  &.is-large {
    @include switch-size($size-large); }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &:checked {
        + label {
          &::before,
          &:before {
            background: $color; } } }
      &.is-outlined {
        &:checked {
          + label {
            &::before,
            &:before {
              background-color: transparent;
              border-color: $color !important; }
            &::after,
            &:after {
              background: $color; } } } }
      &.is-thin {
        &.is-outlined {
          + label {
            &::after,
            &:after {
              box-shadow: none; } } } } }
    &.is-unchecked-#{$name} {
      + label {
        &::before,
        &:before {
          background: $color; } }
      &.is-outlined {
        + label {
          &::before,
          &:before {
            background-color: transparent;
            border-color: $color !important; }
          &::after,
          &:after {
            background: $color; } } } } } }
