.tag:not(body) {
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: darken($color, 20);
      color: $color-invert;
      // If a light and dark colors are provided
      @if length($pair) > 3 {
        $color-light: nth($pair, 3);
        $color-dark: nth($pair, 4);
        &.is-light {
          background-color: darken($color-dark, 15);
          color: $color-light; } } } } }
