@charset "utf-8";

$scheme-main: $black-ter;
$scheme-main-bis: $black-bis;
$scheme-main-ter: $black-ter;
$scheme-invert: $white-ter;
$scheme-invert-bis: $white-bis;
$scheme-invert-ter: $white-ter;

$box-background-color-dark: $black-ter;
$modal-background-background-color-dark: rgba($grey-dark, 0.86);
$button-static-background-color: $black-ter;
$table-background-color-dark: $black-ter;
$button-static-background-color-dark: $black-ter;
$button-static-color-dark: $grey-light;
$navbar-background-color-dark: $black-ter;
$footer-background-color-dark: $black;
$tag-background-color-dark: $black;
$blue-invert: $link-dark;
$link-dark: hsl(209, 71%, 63%);

@import "../../node_modules/bulma-prefers-dark/sass/utilities/_all";
@import "../../node_modules/bulma-prefers-dark/sass/base/_all";
@import "../../node_modules/bulma-prefers-dark/sass/elements/_all";
@import "../../node_modules/bulma-prefers-dark/sass/components/_all";
@import "../../node_modules/bulma-prefers-dark/sass/layout/_all";

.content {
  .table {
    td, th {
      border-left: 0 none;
      border-right: 0 none;
    }
  }
}

.navbar-item {
  .button.is-light {
    background-color: $grey-dark;
    border-color: transparent;
    color: $white-ter;
  }

  .button.is-link.is-light {
    &:hover, &.is-hovered {
      background-color: $grey;
      border-color: transparent;
      color: $white-ter;
    }
  }
}

.navbar-brand {
  a {
    color: $scheme-invert-ter;
    &:hover {
      color: $grey-light;
    }
  }
}

.button.is-link.is-light {
  background-color: $blue-invert;
  color: $white;

  &:hover, &.is-hovered {
    background-color: darken($blue-invert, 2.5%);
    color: $white;
  }
}

.button.is-white{
  background-color: $black;
  color: $grey-light;

  &:hover, &.is-hovered {
    background-color: $black-ter;
    color: $white;
  }

  &:active, &.is-active {
    background-color: $black-ter;
    color: $white;
    box-shadow: none !important;
  }

  &:focus, &.is-focused {
    background-color: $black-ter;
    color: $white;
    box-shadow: none !important;
  }
}

.select > select {
  border-color: $grey-darker !important;
  background-color: $black !important;
  color: #dbdbdb !important;
}

.select > select {
  border-color: $grey-darker !important;
  background-color: $black !important;
  color: #dbdbdb !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $blue;
}

label.civ-select:hover {
  background-color: $scheme-main-ter;
}

.player-inactive {
  background-color: rgba($scheme-main, 0.3);
}

.table-header {
  background-color: darken($scheme-main-ter, 10%);
}

.preset-editor-row {
  background-color: darken($scheme-main-ter, 3%);
  border-bottom: 1px solid darken($scheme-main-ter, 10%);
}

.back-icon.header-navigation {
  color: $scheme-invert !important;
}

.turn-none .bar {
  background-color: $grey;
}

.turn-hidden .bar {
  border-left: .5rem solid $grey;
  border-right: .5rem solid $grey;
}

code, pre {
  color: inherit;
  border-radius: 4px;
  background-color: $black-bis;
}

hr {
  background-color: $grey-dark;
  border-color: $grey-dark;
}

.box {
  border-radius: 4px;
  box-shadow: none;
}

.delete, .modal-close {
  background-color: rgba(100, 100, 100, 0.5);
}

.message {
  .delete, .modal-close {
    background-color: rgba(10, 10, 10, 0.2);
  }
}

#draft-title a {
  color: $scheme-invert-ter;
}

.civ-selector-tabs.tabs.is-boxed {
  ul {
    li.is-active {
      a, a:hover {
        background-color: #242424;
      }
    }
    li {
      a:hover {
        background-color: #363636;
      }
    }
  }
}

@import "bulma-dark-tags";
@import "bulma-dark-switch";
